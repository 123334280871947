import React, { useState, Fragment } from 'react'
import Proptypes from 'prop-types'
import Address from "./BuyCartAddress"
import Checkbox from "../Checkbox"
import Textarea from "../Textarea"
import AddAddress from "./BuyCartAddAddress"
import SvgIcon from "../SvgCurve";

export default function BuyCartDelivery(props) {
    const { addresses } = props;

    const [showForm, setShowForm] = useState(false);

    const showFormAddress = () => setShowForm(!showForm)

    const renderAddresses = () => {
        return addresses && addresses.map((item) => {
            return (
                <Address text={item} />
            )
        })
    }

    const renderForm = () => {
        if (!showForm) return <Fragment />

        return <AddAddress />
    }

    return (
        <div className="BuyCartDelivery">
            <span className="BuyCartDelivery-title"><SvgIcon className="BuyCartDelivery-icon" icon="box" />Entrega</span>
            <div className="BuyCartDelivery-addresses">
                <span className="BuyCartDelivery-text p-0">Indica tu dirección de entrega</span>
                {renderAddresses()}
                <button onClick={showFormAddress} className="BuyCartDelivery-btn">AÑADIR DIRECCIÓN</button>
            </div>
            {renderForm()}
            <span className="BuyCartDelivery-subtitle">¿Tienes un cupón de descuento?</span>
            <div className="BuyCartDelivery-discount">
                <input className="BuyCartDelivery-input" type="text" />
                <button className="BuyCartDelivery-apply">Aplicar</button>
            </div>
            <span className="BuyCartDelivery-text">Introduce tu cupón de descuento o tarjeta regalo</span>
            <span className="BuyCartDelivery-subtitle align d-flex align-items-center"><SvgIcon className="BuyCartDelivery-gift" icon="gift" />Lo quieres para regalar?</span>
            <Checkbox className="BuyCartDelivery-checkbox" classNameLabel="BuyCartDelivery-checkLabel" text="Solicita envoltura para regalo. Enviamos tu pedido listo para regalar (sin coste añadido)" />
            <Checkbox className="BuyCartDelivery-checkbox" classNameLabel="BuyCartDelivery-checkLabel" text="Solicita ticket regalo. No incluye el precio de los artículos." />
            <span className="BuyCartDelivery-text mt-5 mb-1">Comentarios / Observaciones sobre su pedido:</span>
            <div className="BuyCartDelivery-textareaWrapper">
                <Textarea />
            </div>
            <Checkbox className="BuyCartDelivery-checkbox" classNameLabel="BuyCartDelivery-checkLabel" text="He leído y acepto las Condiciones de compra" />
        </div>
    )
}

BuyCartDelivery.defaultProps = {
    addresses:[]
}

BuyCartDelivery.propTypes = {
    addresses : Proptypes.array,
}
