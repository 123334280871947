import React, { Fragment } from 'react'

export default function Textarea(props) {
    const { id, text } = props;
    return (
        <Fragment>
            <label className="Textarea-label" htmlFor={id}>{text}</label>
            <textarea className="Textarea"></textarea>
        </Fragment>
        
    )
}
