import React, {useState} from 'react'
import Proptypes from "prop-types"

export default function BuyCartAddress(props) {
    const { text } = props

    const [selected, setSelected] = useState(false)
    const selectAddress = () => setSelected(!selected)

    return (
        <button onClick={selectAddress} className={selected ? "BuyCartAddress selected" : "BuyCartAddress"}>
            <span className="BuyCartAddress-text">{text}</span>
        </button>
    )
}

BuyCartAddress.defaultProps = {
    text:"",
}

BuyCartAddress.propTypes = {
    text: Proptypes.string
}