import React from 'react'
import Input from "../Input"
import Select from "../Select"
import Checkbox from "../Checkbox"

export default function BuyCartAddAddress() {
    return (
        <div className="BuyCartAddAddress">
            <div className="BuyCartAddAddress-container">
                <form action="">
                    <Input className="BuyCartAddAddress-input" label="Nombre del destinatario" />
                    <Input  className="BuyCartAddAddress-input" label="Dirección principal" />
                    <Select  className="BuyCartAddAddress-input" label="Pais" />
                    <div className="BuyCartAddAddress-wrapper">
                        <Input  className="BuyCartAddAddress-input space" label="Localidad" />
                        <Input  className="BuyCartAddAddress-input" label="Código Postal" />
                    </div>
                    <Input  className="BuyCartAddAddress-input" label="Teléfono" />
                    <Input className="BuyCartAddAddress-input" label="Como quieres guardar esta dirección (casa, trabajo, etc.)" />
                    <Checkbox className="BuyCartAddAddress-checkbox" classNameLabel="BuyCartAddAddress-checkLabel" text="Convierte esta dirección como principal" />
                    <Checkbox className="BuyCartAddAddress-checkbox" classNameLabel="BuyCartAddAddress-checkLabel" text="Esta es mi dirección de facturación" />
                    <button className="BuyCartAddAddress-btn">Guardar</button>
                </form>
            </div>
        </div>
    )
}
