import React from 'react'
import Layout from "../../components/Layout"
import ShoppingCartResumen from "../../components/ShoppingCart/ShoppingCartResume"
import ShoppingCartDelivery from "../../components/BuyCart/BuyCartDelivery"
import { addresses } from "../../data/address-cart"
import StepCart from '../../components/StepCart'
import { navigate } from "gatsby"

export default function BuyCart(props) {
    const { path } = props

    const redirectPage = () => {
        return navigate("/cesta/success/")
    }

    return (
        <Layout>
            <div className="BuyCart">
                <div className="BuyCart-container">
                    <div className="row">
                        <div className="col-12">
                        <StepCart path={path} />
                        </div>
                        <div className="col-12 col-md-7">
                            <ShoppingCartDelivery addresses={addresses} />
                        </div>
                        <div className="col-12 col-md-5">
                            <ShoppingCartResumen redirectPage={redirectPage} quantity="1" subtotal="20.50" shippingCost="4.59" total="25.09" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
